html {
  overflow-y: hidden;
}

body {
  background-color: #f1f3e8;
  padding-top: 3.75rem !important;
  overflow-y: scroll;
}

html, body {
    margin: 0;
    height: 100%;
}

html.has-navbar-fixed-top {
  padding-top: 0px !important;
}

nav.navbar {
  background-color: transparent;
	backdrop-filter: blur(3px);
	margin-left: 2rem;
	margin-right: 2rem;
	margin-top: 1rem;
}

.card {
  border-radius: 5px;
}

.footer {
  background-color: #f1f3e8 !important;
  color: grey;
  
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  
  padding: 1rem !important;
}
